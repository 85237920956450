.header {
    background-color: gray;
    color: white;
    padding: 5px 0px;
}
.header > div {
    padding: 0 5px;
    text-transform: capitalize;
    text-align: center;
}
.item {
    position: relative;
    border-bottom: 1px solid gray;
}
.recalc {
    position: absolute;
    top: 0;
    right: -50px;
    color: red;
}
.item > div {
    padding: 0 5px;
    text-align: center;
}
.header, .item {
    border-left: 1px solid gray;
    border-right: 1px solid gray;
    max-width: max-content;
    margin: auto;
    display: grid;
    grid-template-columns: 30px 80px 400px 400px 200px 150px 150px 150px;
}
.item > div:not(:last-child) {
    border-right: 1px solid gray;
}
.teams {
    display: flex;
    /* flex-direction: column; */
    border: none;
    justify-content: center;
}
.list {
    /* margin: 0 5px; */
    /* border-left: 1px solid gray; */
    /* border-right: 1px solid gray; */
}